import { jsormNormalize } from 'services/util'
import { getAnswersForQuestion } from '../answers/byQuestionId'
import { getOfferForAnswer } from '../offers/byAnswerId'

const questions = (state = {}, action) => {
  switch (action.type) {
    // case 'ADD_OFFER':
    //   const offers = state.offers.concat(action.offer)
    //   return Object.assign({}, state, { offers })
    case 'UPDATE_QUESTION_SUCCESS':
    case 'ADD_QUESTION':
      return { ...state, [action.question.id]: action.question.attributes }
    case 'DELETE_QUESTION_SUCCESS':
      const { [action.questionId]: deleted, ...newState } = state
      return newState
    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      const newQuestions = jsormNormalize(action.quiz.questions, 'id')
      return { ...state, ...newQuestions }
    default:
      return state
  }
}

export default questions

export const getQuestion = (state, id) => state.questions.byId[id]

export const getQuestionWithAnswers = (state, questionId) => {
  const question = getQuestion(state, questionId)
  const answers = getAnswersForQuestion(state, questionId).map(answer => ({
    ...answer,
    offer: (getOfferForAnswer(state, answer.id) || {}).id
  }))
  return { ...question, answers }
}
