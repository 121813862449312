import { Attr, BelongsTo, HasMany, Model } from 'jsorm'
import ApplicationRecord from './ApplicationRecord'

import { Answer, Quiz } from 'models'

@Model()
export class Question extends ApplicationRecord {
  static jsonapiType = 'questions'

  @BelongsTo() quiz: Quiz
  @HasMany() answers: Answer[]

  @Attr() id: string
  @Attr() prompt: string
  @Attr() position: number
  @Attr() image: object
}
