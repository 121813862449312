import { jsormNormalize } from 'services/util'

const answers = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      const answersArray = action.quiz.questions.reduce(
        (accumulator, question) => accumulator.concat(question.answers),
        []
      )

      return { ...state, ...jsormNormalize(answersArray, 'id') }

    case 'UPDATE_QUESTION_SUCCESS':
      return { ...state, ...jsormNormalize(action.question.answers, 'id') }
    default:
      return state
  }
}

export default answers

export const getAnswer = (state, id) => state.answers.byId[id]
