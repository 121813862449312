import { combineReducers } from 'redux'
import byId from './byId'
import byQuestionId from './byQuestionId'

const answers = combineReducers({
  byId,
  byQuestionId
})

export default answers
