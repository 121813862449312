import { Attr, BelongsTo, HasMany, Model } from 'jsorm'
import { Offer, Question, User } from 'models'

import ApplicationRecord from './ApplicationRecord'

@Model()
export class Quiz extends ApplicationRecord {
  static jsonapiType = 'quizzes'
  @HasMany() questions: Question[]
  @HasMany() offers: Offer[]
  @BelongsTo() user: User

  @Attr() answerButtonColor: string
  @Attr() answerFontColor: string
  @Attr() answerFontFamily: string
  @Attr() answersHolderColor: string
  @Attr() blockAds: boolean
  @Attr() buttonFontColor: string
  @Attr() buttonFontFamily: string
  @Attr({ persist: false })
  createdAt: string
  @Attr() customAd: string
  @Attr() description: string
  @Attr() format: string
  @Attr() hideLogo: boolean
  @Attr() id: string
  @Attr() image: { original: string }
  @Attr() imageBlurColor: string
  @Attr() leadGenerationButtonText: string
  @Attr() leadGenerationPromptText: string
  @Attr() notes: string
  @Attr() paragraphFontColor: string
  @Attr() paragraphFontFamily: string
  @Attr() progressFontColor: string
  @Attr() progressFontFamily: string
  @Attr() published: boolean
  @Attr() requireLeadGenerationForResults: boolean
  @Attr() titleFontColor: string
  @Attr() titleFontFamily: string
  @Attr() title: string
  @Attr({ persist: false })
  updatedAt: string
}
