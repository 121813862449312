import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import {Tabs, Tab} from 'material-ui/Tabs'

class QuizEditHeader extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  }

  tabPath(path){
    const countOfSlashes = (path.match(/\//g) || []).length

    if(countOfSlashes > 3) {
      return path.substr(0, path.lastIndexOf('\/'))
    } else {
      return path
    }
  }

  render() {
    const { router, children, params: { id } } = this.props
    return (
      <div>
        <Tabs onChange={router.push} value={this.tabPath(this.props.location.pathname)}>
          <Tab label="Start Page" value={`/quizzes/${id}/edit`} />
          <Tab label="Questions" value={`/quizzes/${id}/questions`} />
          <Tab label="Outcomes" value={`/quizzes/${id}/offers`} />
        </Tabs>
        <div>
          {children}
        </div>
      </div>
    )
  }
}

export default withRouter(QuizEditHeader)
