import { Answer, Offer, Question, Quiz } from 'models'
import { push, replace } from 'react-router-redux'

export const ADD_QUESTION = 'ADD_QUESTION'

export const UPDATE_QUESTION_REQUEST = 'UPDATE_QUESTION_REQUEST'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE'

export const addQuestion = (quizId, question) => (dispatch, getState) => {
  dispatch({ type: ADD_QUESTION, quizId, question })
  dispatch(push(`/quizzes/${quizId}/questions/${question.id}`))
}

export const deleteQuestion = (quizId, questionId) => (dispatch, getState) => {
  dispatch({ type: DELETE_QUESTION_REQUEST, quizId, questionId })

  const question = Question.load(questionId) as Question

  question.delete().then(response => {
    dispatch({ type: DELETE_QUESTION_SUCCESS, quizId, questionId })
    dispatch(replace(`/quizzes/${quizId}/questions`))
  })
}

export const fetchQuiz = quizId => async (dispatch, getState) => {
  Quiz.includes(['offers', { questions: { answers: 'offer' } }])
    .find(quizId)
    .then(({ data: quiz }) => {
      dispatch({
        type: 'FETCH_QUIZ_SUCCESS',
        quiz
      })
    })
    .catch(err => {
      alert('there was an error fetching the quiz')
    })
}

export const updateQuestions = (quizId: string, questions: Question[]) => (dispatch, getState) => {
  const quiz: Quiz = Quiz.load(quizId) as Quiz
  quiz.questions = questions.map(question => {
    const q = Question.load(question.id) as Question
    q.assignAttributes(question)
    return q
  })

  quiz
    .save({ with: 'questions' })
    .then(response => {
      dispatch({
        type: 'UPDATE_QUIZ_SUCCESS',
        quiz
      })
    })
    .catch(error => {
      dispatch({
        type: 'UPDATE_QUIZ_FAILURE',
        message: error.message || 'Something went wrong.'
      })
    })
}

export const updateQuestion = (quizId, data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_QUESTION_REQUEST, data })

  const quiz = Quiz.load(quizId) as Quiz
  const question = Question.load(data.id) as Question

  question.assignAttributes(data)

  question.answers = data.answers.map((answerAttrs, index) => {
    const answer = answerAttrs ? (Answer.load(answerAttrs.id) as Answer) : new Answer()
    answer.assignAttributes({ ...answerAttrs, position: index + 1 })

    if (answerAttrs.offer) {
      const offer = Offer.load(answerAttrs.offer) as Offer
      answer.offer = offer
    }

    if (answerAttrs._markedForDisassociation) {
      answer.isMarkedForDisassociation = true
    }
    return answer
  })
  question.quiz = quiz

  question
    .save({ with: ['quiz', { answers: 'offer' }] })
    .then(result => {
      dispatch({ type: 'UPDATE_QUESTION_SUCCESS', question })
    })
    .catch(err => {
      dispatch({ type: 'UPDATE_QUESTION_ERROR', question, err })
    })
}

// export const updateQuestion = (id, data, quiz) => (dispatch, getState) => {
//   const qLens = lensProp('questions')
//   const update = curry((id, data, question) => {
//     if (question.id === id) {
//       return { ...question, ...data }
//     } else {
//       return question
//     }
//   })(id)(data)

//   const updatedQuiz = over(qLens, map(update), quiz)
//   dispatch({ type: 'UPDATE_QUIZ_REQUEST', id, data: updatedQuiz })

//   return api.updateQuiz(quiz.id, quiz).then(
//     response => {
//       dispatch({
//         type: 'UPDATE_QUIZ_SUCCESS',
//         response
//       })
//     },
//     error => {
//       dispatch({
//         type: 'UPDATE_QUIZ_FAILURE',
//         message: error.message || 'Something went wrong.'
//       })
//     }
//   )
// }
