import { DELETE_OFFER_SUCCESS } from 'reducers/offers'
import { ADD_OFFER } from 'reducers/offers/byId'

import { FETCH_QUIZ_SUCCESS } from '../quizzes'
import { getOffer } from './byId'

const initialState = {}

const byQuizId = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFER:
      return { ...state, [action.quizId]: [...state[action.quizId], action.offer.id] }

    case DELETE_OFFER_SUCCESS:
      const { offerId, quizId } = action
      const offerArray = [...state[quizId]].filter(id => id !== offerId)
      return { ...state, [action.quizId]: offerArray }

    case FETCH_QUIZ_SUCCESS:
      const { offers } = action.quiz
      const ids = offers.map(offer => offer.id)
      return { ...state, [action.quiz.id]: ids }

    default:
      return state
  }
}

export default byQuizId
export const getOffersForQuiz = (state, quizId) =>
  (state.offers.byQuizId[quizId] || []).map(offerId => {
    return getOffer(state, offerId)
  })
