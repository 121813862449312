import { jsormNormalize } from 'services/util';

import { FETCH_QUIZZES_SUCCESS, UPDATE_QUIZ_SUCCESS } from '../quizzes';

const initialState = {}

const byId = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUIZ_SUCCESS:
      const { user } = action.quiz
      if (user) {
        return { ...state, [user.id]: user.attributes }
      } else {
        return state
      }

    case FETCH_QUIZZES_SUCCESS:
      const usersArray = action.quizzes.map(quiz => quiz.user)

      const users = jsormNormalize(usersArray, 'id')
      return { ...state, ...users }

    default:
      return state
  }
}

export default byId

export const getUser = (state, id: string) => state.users.byId[id]
