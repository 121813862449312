import { Question } from 'models'
import { getQuestion } from 'reducers/questions/byId'

export const getQuestionsForQuiz = (state, quizId: string): Question[] => {
  return (state.questions.byQuizId[quizId] || []).map(questionId => getQuestion(state, questionId))
}

const data = (state = {}, action) => {
  switch (
    action.type
    // case 'FETCH_QUIZ_SUCCESS':
  ) {
  }
}
