import { removeIdFromArray } from 'services/util'

const byQuizId = (state: {} = {}, action) => {
  const { quizId, question, questionId } = action
  switch (action.type) {
    case 'ADD_QUESTION':
      return { ...state, [quizId]: [...state[quizId], question.id] }

    case 'DELETE_QUESTION_SUCCESS':
      return { ...state, [quizId]: removeIdFromArray(state[quizId], questionId) }

    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      const { quiz } = action
      const ids: string[] = quiz.questions
        .sort((questionA, questionB) => questionA.position - questionB.position)
        .map(question => question.id)
      return { ...state, [quiz.id]: ids }
    default:
      return state
  }
}

export default byQuizId
