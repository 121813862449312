import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

// setup custom Material UI theme
var _colors = require('material-ui/styles/colors')
var _colorManipulator = require('material-ui/utils/colorManipulator')
var _spacing = require('material-ui/styles/spacing')
var _spacing2 = _interopRequireDefault(_spacing)
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj } }

const furtherInsightsPurple = '#6140FF'
const furtherInsightsYellow = '#FFCE52'
const furtherInsightsPink = '#CE6DE7'
const newsUpBlue = '#6FC6EF'

const furtherInsightsTheme = {
  spacing: _spacing2.default,
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color: furtherInsightsPurple, //_colors.cyan500,
    primary2Color: newsUpBlue, //_colors.cyan700,
    primary3Color: _colors.grey400,
    accent1Color: furtherInsightsYellow, //_colors.pinkA200,
    accent2Color: newsUpBlue, //_colors.grey100,
    accent3Color: _colors.grey500,
    textColor: _colors.darkBlack,
    secondaryTextColor: (0, _colorManipulator.fade)(_colors.darkBlack, 0.54),
    alternateTextColor: _colors.white,
    canvasColor: _colors.white,
    borderColor: _colors.grey300,
    disabledColor: (0, _colorManipulator.fade)(_colors.darkBlack, 0.3),
    pickerHeaderColor: _colors.cyan500,
    clockCircleColor: (0, _colorManipulator.fade)(_colors.darkBlack, 0.07),
    shadowColor: _colors.fullBlack
  }
}

class AppContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
    routerKey: PropTypes.number,
    store: PropTypes.object.isRequired
  }

  render () {
    const { history, routes, routerKey, store } = this.props

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(furtherInsightsTheme)}>
        <Provider store={store}>
          <Router history={history} children={routes} key={routerKey} />
        </Provider>
      </MuiThemeProvider>
    )
  }
}

export default AppContainer
