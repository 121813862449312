import { FETCH_QUIZ_SUCCESS, UPDATE_QUIZ_SUCCESS } from 'reducers/quizzes'
import { jsormNormalize } from 'services/util'
import { UPDATE_OFFER_SUCCESS } from './index'
const initialState = {}

export const ADD_OFFER = 'ADD_OFFER'

const offers = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_OFFER_SUCCESS':
    case 'ADD_OFFER':
      return { ...state, [action.offer.id]: action.offer.attributes }

    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      return { ...state, ...jsormNormalize(action.quiz.offers || {}, 'id') }

    case 'DELETE_OFFER_SUCCESS':
      const { [action.offerId]: deleted, ...newState } = state
      return newState

    default:
      return state
  }
}

export default offers

export const getOffer = (state, id) => state.offers.byId[id]
