import { injectReducer } from 'store/reducers'

export default (store) => ({
  path: ':question_id',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const QuestionEditForm = require('./containers/QuestionEditFormContainer').default
      const reducer = require('reducers/quizzes').default
      const questionsReducer = require('reducers/questions').default
      const answersReducer = require('reducers/answers').default
      const offersReducer = require('reducers/offers').default

      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, { key: 'quizzes', reducer })
      injectReducer(store, { key: 'questions', reducer: questionsReducer })
      injectReducer(store, { key: 'answers', reducer: answersReducer })
      injectReducer(store, { key: 'offers', reducer: offersReducer })
      /*  Return getComponent   */
      cb(null, QuestionEditForm)

    /* Webpack named bundle   */
    }, 'questionEditForm')
  }
})
