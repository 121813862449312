// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import QuizIndex from './QuizIndex'
import QuizPreview from './QuizPreview'
import QuizEdit from './QuizEdit'
import QuizAnalytics from './Analytics'
import GoogleAnalytics from './GoogleAnalytics'
import LeadGeneration from './LeadGeneration'

import Home from './Home'
import * as auth from 'services/auth'
import { curry } from 'ramda'

const requireAuth = curry((prevState, nextState, replace) => {
  if (nextState.location.pathname === '/') {
    if (auth.isLoggedIn()) {
      replace({ pathname: '/quizzes' })
    }
  } else if (!auth.isLoggedIn()) {
    auth.logout()
    replace({ pathname: '/' })
  }
})

export const createRoutes = (store) => ({
  path: '/',
  component: CoreLayout,
  indexRoute: Home,
  childRoutes: [
    QuizIndex(store),
    QuizEdit(store),
    QuizPreview(store),
    QuizAnalytics(store),
    GoogleAnalytics(store),
    LeadGeneration(store)
  ],
  onChange: requireAuth,
  onEnter: requireAuth(null)
})

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

export default createRoutes
