import { JSORMBase } from "jsorm";

// generates a 32-bit integer hash on a string
export function hashString(text) {
  let hash = 0
  if (text && (text.length > 0)) {
    for (let i = 0; i < text.length; i++) {
      const chr = text.charCodeAt(i)
      hash = ((hash << 5) - hash) + chr
      hash |= 0 // convert to 32bit integer
    }
  }
  return hash
}

export function isGradedQuiz(quiz) {
  return quiz && quiz.format && (quiz.format.toLowerCase() === 'gradedquiz')
}

export function getQuestionPosition(questionText, quiz) {
  let position = -1

  if (quiz && quiz.questions && quiz.questions.length) {
    for (const question of quiz.questions){
      if (questionText.toLowerCase() === question.toLowerCase()) {
        position = question.position
        break
      }
    }
  }
  // debug.log("Q: '" + questionText + "', position: " + position)

  return position
}

export function camelToKebab(string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function getQuestionEntryIndex(questionText, quiz) {
  let entryIndex = -1

  if (quiz && quiz.questions && quiz.questions.length) {
    for (let index = 0; index < quiz.questions.length; index += 1) {
      if (questionText.toLowerCase() === quiz.questions[index].prompt.toLowerCase()) {
        entryIndex = index
        break
      }
    }
  }
  // debug.log("Q: '" + questionText + "', array index: " + entryIndex)

  return entryIndex
}

export function getAnswerPosition(questionIndex, answerText, quiz) {
  let position = -1

  if (quiz && quiz.questionIndex && (quiz.questions.length > 0) && (questionIndex >= 0) && (questionIndex < quiz.questions.length) &&
    quiz.questions[questionIndex].answers && (quiz.questions[questionIndex].answers.length > 0)) {
    for (let index = 0; index < quiz.questions[questionIndex].answers.length; index += 1) {
      if (answerText.toLowerCase() === quiz.questions[questionIndex].answers[index].text.toLowerCase()) {
        position = quiz.questions[questionIndex].answers[index].position
        break
      }
    }
  }
  // debug.log("A: '" + answerText + "', position: " + position)
  return position
}

export function quizQuestionAnswerIsCorrect(questionIndex, answerText, quiz) {
  let correct = null

  if (isGradedQuiz(quiz) && quiz.questions && (quiz.questions.length > 0) && (questionIndex >= 0) && (questionIndex < quiz.questions.length) &&
    quiz.questions[questionIndex].answers && (quiz.questions[questionIndex].answers.length > 0)) {
    for (let index = 0; index < quiz.questions[questionIndex].answers.length; index += 1) {
      if (answerText.toLowerCase() === quiz.questions[questionIndex].answers[index].text.toLowerCase()) {
        correct = quiz.questions[questionIndex].answers[index].isCorrect
        break
      }
    }
  }

  return correct
}

export const jsormNormalize = (objects: JSORMBase[], keyField) => {
  const attrArray = objectsToAttributes(objects)
  return arrayToObject(attrArray, keyField)
}

export const removeIdFromArray = (array: any[], id: any) => {
  return array.filter(itemId => itemId !== id)
}

export const objectsToAttributes = objects => {
  return objects.map(obj => obj.attributes)
}

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})

export const dateRanges = {
  'Today': {
    startDate: (now) => {
      return now
    },
    endDate: (now) => {
      return now
    }
  },

  'Yesterday': {
    startDate: (now) => {
      return now.add(-1, 'days')
    },
    endDate: (now) => {
      return now.add(-1, 'days')
    }
  },

  'Last 7 Days': {
    startDate: (now) => {
      return now.add(-7, 'days')
    },
    endDate: (now) => {
      return now
    }
  },

  'Last 30 Days': {
    startDate: (now) => {
      return now.add(-30, 'days')
    },
    endDate: (now) => {
      return now
    }
  },
  'Last 90 Days': {
    startDate: (now) => {
      return now.add(-90, 'days')
    },
    endDate: (now) => {
      return now
    }
  },
  'All Time': {
    startDate: (now) => {
      now.year('2005')
      now.dayOfYear(1)
      return now
    },
    endDate: (now) => {
      return now
    }
  }
}
