import { FETCH_QUIZZES_SUCCESS } from '../quizzes'
import { getUser } from './byId'

const initialState = {}

const byQuizId = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUIZZES_SUCCESS:
      const usersMappedByQuizId = action.quizzes.reduce((accumulator, quiz) => {
        if (quiz.user) {
          accumulator[quiz.id] = quiz.user.id
        }
        return accumulator
      }, {})

      return { ...state, ...usersMappedByQuizId }

    default:
      return state
  }
}

export default byQuizId

export const getUserByQuizId = (state, quizId) => getUser(state, state.users.byQuizId[quizId])
