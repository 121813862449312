import { reducer as auth } from 'modules/auth'
import { routerReducer as router } from 'react-router-redux'
import answers from 'reducers/answers'
import offers from 'reducers/offers'
import questions from 'reducers/questions'
import quizzes from 'reducers/quizzes'
import users from 'reducers/users'
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    // Add sync reducers here
    form,
    router,
    auth,
    quizzes,
    offers,
    questions,
    answers,
    users,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
