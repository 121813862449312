import React from 'react'

export const HomeView = () => (
  <center>
    <div>
      <h5>Welcome to the Further Insights Quiz Management System</h5>
      <p>Login to continue to your personal quiz repository.</p>
      <br />
      <img src={"/img/further-insights-vlogo.png"}></img>
    </div>
  </center>
)

export default HomeView
