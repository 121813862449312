import { combineReducers } from 'redux';

import byId from './byId';

export const FETCH_QUIZZES_SUCCESS = 'FETCH_QUIZZES_SUCCESS';
export const UPDATE_QUIZ_SUCCESS = 'UPDATE_QUIZ_SUCCESS';
export const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS';
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS';

export const DELETE_QUIZ_REQUEST = 'DELETE_QUIZ_REQUEST';
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS';
export const DELETE_QUIZ_FAILURE = 'DELETE_QUIZ_FAILURE';

const quizzes = combineReducers({
    byId
})

export default quizzes