import { Question } from 'models'
import { FETCH_QUIZ_SUCCESS } from 'reducers/quizzes'

import { UPDATE_QUESTION_SUCCESS } from '../../routes/QuestionEdit/actions'
import { getOffer } from './byId'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUESTION_SUCCESS:
      const { question } = action

      return { ...state, ...idMapForQuestions([question]) }

    case FETCH_QUIZ_SUCCESS:
      const {
        quiz: { questions }
      } = action

      return { ...state, ...idMapForQuestions(questions) }

    default:
      return state
  }
}

const idMapForQuestions = (questions: Question[]) => {
  const idMap = questions.reduce((accumulator, question) => {
    question.answers.map(answer => {
      accumulator[answer.id] = answer.offer && answer.offer.id
      return accumulator
    })
    return accumulator
  }, {})

  return idMap
}

export const getOfferForAnswer = (state, answerId) => getOffer(state, state.offers.byAnswerId[answerId])
