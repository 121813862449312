import { combineReducers } from 'redux'

import byAnswerId from './byAnswerId'
import byId from './byId'
import byQuizId from './byQuizId'

export const UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST'
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS'

export const DELETE_OFFER_REQUEST = 'DELETE_OFFER_REQUEST'
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS'

const offers = combineReducers({
  byId,
  byQuizId,
  byAnswerId
})

export default offers
