import { removeIdFromArray } from 'services/util'
import { getAnswer } from './byId'

import { UPDATE_QUESTION_SUCCESS } from '../../routes/QuestionEdit/actions'

const byQuestionId = (state: {} = {}, action) => {
  const { answer, questionId } = action
  switch (action.type) {
    // case 'ADD_QUESTION':
    //   return { ...state, [questionId]: [...state[questionId], answer.id] }

    // case 'REMOVE_QUESTION':
    //   return { ...state, [quizId]: removeIdFromArray(state[quizId], questionId) }

    case UPDATE_QUESTION_SUCCESS:
      const { answers } = action.question
      const ids = answers
        .sort((answerA, answerB) => answerA.position - answerB.position)
        .map(answer => answer.id)
      return {...state, [action.question.id]: ids}

    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      const { quiz } = action

      const newAnswers = action.quiz.questions.reduce((accumulator, question) => {
        accumulator[question.id] = question.answers
          .sort((answerA, answerB) => answerA.position - answerB.position)
          .map(answer => answer.id)
        return accumulator
      }, {})

      return { ...state, ...newAnswers }
    default:
      return state
  }
}

export default byQuestionId

export const getAnswersForQuestion = (state, questionId: string) =>
  (state.answers.byQuestionId[questionId] || []).map(answerId => getAnswer(state, answerId))
