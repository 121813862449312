import { getOffersForQuiz } from 'reducers/offers/byQuizId'
import { getQuestionsForQuiz } from 'routes/QuestionEdit/reducers'
import { jsormNormalize } from 'services/util'

import { getQuestionWithAnswers } from '../questions/byId'

// import { CREATE_QUIZ_SUCCESS, DELETE_QUIZ_SUCCESS, FETCH_QUIZ_SUCCESS, FETCH_QUIZZES_SUCCESS, UPDATE_QUIZ_SUCCESS } from 'reducers/quizzes';

// const FETCH_QUIZZES_SUCCESS = 'FETCH_QUIZZES_SUCCESS';
const quizzes = (state = {}, action) => {
  const { quiz } = action
  switch (action.type) {
    // case 'ADD_OFFER':
    //   const offers = state.offers.concat(action.offer)
    //   return Object.assign({}, state, { offers })
    // case 'ADD_QUESTION':
    //   const questions = state.questions.concat(action.question)
    //   return Object.assign({}, stDate, { questions: questions })
    case 'FETCH_QUIZZES_SUCCESS':
      const quizzes = jsormNormalize(action.quizzes, 'id')
      return { ...state, ...quizzes }

    case 'CREATE_QUIZ_SUCCESS':
    case 'UPDATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      return { ...state, [quiz.id]: quiz.attributes }

    case 'DELETE_QUIZ_SUCCESS':
      const { [quiz.id]: deletedId, ...newState } = state
      return newState

    default:
      return state
  }
}

export default quizzes

export const getQuizzes = state => state.quizzesIndex.indexList.map(quizId => getQuiz(state, quizId))
export const getQuiz = (state, quizId) => state.quizzes.byId[quizId]
export const updateQuiz = state => state.quiz

export const getFlattenedQuiz = (state, id) => {
  const quiz = getQuiz(state, id)
  const offers = getOffersForQuiz(state, id)
  const questions = getQuestionsForQuiz(state, id).map(question => getQuestionWithAnswers(state, question.id))
  return { ...quiz, offers, questions }
}
