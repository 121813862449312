import { combineReducers } from 'redux'
import byId from './byId'
import byQuizId from './byQuizId'

const questions = combineReducers({
  byId,
  byQuizId
})

export default questions
